import routesClientes from "./clientes";
import routesPedidos from "./pedidos";
import routesRelatorios from "./relatorios";
import routesSegmentos from "./segmentos";
import routesSugestoesConteudo from "./sugestoes-conteudo";
import routesTemplatesNotificacoes from "./templates-notificacoes";
import routesTermosUso from "./termos-uso";
import routesUsuarios from "./usuarios";
import routeGestaocliente from './gestao-clientes'
import routeEnvioDeEmails from './envio-de-emails'
import routeCalendarioFeriados from './calendario-feriado'

export default [
  ...routesClientes, ...routesPedidos, ...routesRelatorios, 
  ...routesSegmentos, ...routesSugestoesConteudo, ...routesTemplatesNotificacoes, 
  ...routesTermosUso, ...routesUsuarios, ...routeGestaocliente, ...routeEnvioDeEmails,
  ...routeCalendarioFeriados
];