import Roles from "@/utils/roles"

export default [
    {
        path: "/calendario-feriados",
        name: "feriados",
        component: () => import("@/views/area-mention/calendario-feriados/index.vue"),
        meta: {
          title: 'Calendário de Feriados',
          authRequired: true,
          roles: [Roles.AcessarCalendarioFeriados]
        },
    },
];