import Roles from "@/utils/roles"
import routesMinhaConta from "./minha-conta";
import routesPedidos from "./pedidos";
import routesPlanoComunicacao from "./plano-comunicacao";
import routesSugestoesConteudo from "./sugestoes-conteudo";
import routesUsuarios from "./usuarios";

const routes = [
    // Dashboards
    {
        path: "/minhas-publicacoes",
        name: "minhas-publicacoes",
        component: () => import("@/views/area-cliente/publicacoes/index.vue"),
        meta: {
          title: 'Minhas Publicações',
          authRequired: true,
          roles: [Roles.RelatorioPublicacoes]
        },
      },
      {
        path: "/responder-pesquisa",
        name: "responder-pesquisa",
        component: () => import("@/views/area-cliente/responder-pesquisa"),
        meta: {
          title: 'Responder Pesquisa',
          authRequired: true,
          roles: [Roles.ResponderPesquisa]
        },
      },
      {
        path: "/solicitacao-reuniao-comercial",
        name: "solicitacao-reuniao-comercial",
        component: () => import("@/views/formularios/externo/solicitacao-reuniao-comercial.vue"),
        meta: {
          title: 'Agendamento reunião comercial',
          authRequired: false,
        },
      },
      {
        path: "/obrigado",
        name: "obrigado",
        component: () => import("@/views/formularios/obrigado.vue"),
        meta: {
          title: 'Obrigado',
          authRequired: false,
        },
      },
      {
        path: "/pesquisa-satisfacao/encerramento",
        name: "pesquisa-ultimo-dia-atendimento",
        component: () => import("@/views/area-cliente/pesquisa-satisfacao/index.vue"),
        meta: {
          title: 'Responder Pesquisa de Satisfação',
          authRequired: true, 
          roles: [Roles.IsCliente]
        },
      },
];

export default [...routes, ...routesMinhaConta, ...routesPedidos, 
    ...routesPlanoComunicacao, ...routesSugestoesConteudo, ...routesUsuarios];