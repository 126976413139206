import store from "@/state/store"
//import { getLinkWhatsapp } from "./functions";

const Roles = {
    //Sistemicas/temporarias
    AllLoggedUsers: "allLoggedUsers",
    IsMention: "uM",
    IsCliente: "uC",

    AcceptTerms: "acceptTerms",
    FillClientData: "fillClientData",
    ResponderPesquisa: "pesquisa",

    // Clientes
    LerMeusPedidos: "100",
    RevisarMeusPedidos: "101",
    CriarMeusPedidos: "102",
    ComentarMeusPedidos: "103",
    ExcluirMeusPedidos: "104",
    GerenciarArquivosMeusPedidos: "105",
    CompartilharMeusPedidos: "106",
    LerMinhasSugestoes: "107",
    AvaliarMinhasSugestoes: "108",
    LerMinhaEmpresa: "109",
    EditarMinhaEmpresa: "110",
    LerMeuPlanoComunicacao: "111",
    RevisarMeuPlanoComunicacao: "112",
    ComentarMeuPlanoComunicacao: "113",
    CompartilharMeuPlanoComunicacao: "114",
    LerMeusUsuarios: "115",
    AdicionarMeusUsuarios: "116",
    EditarMeusUsuarios: "117",
    RelatorioPublicacoes: "118",

    // Mention
    LerTodosPedidos: "500",
    ProducaoTodosPedidos: "501",
    RevisaoTodosPedidos: "502",
    ComentarTodosPedidos: "503",
    ExcluirTodosPedidos: "504",
    LerClientesPedidos: "505",
    ProducaoClientesPedidos: "506",
    RevisaoClientesPedidos: "507",
    ComentarClientesPedidos: "508",
    ExcluirClientesPedidos: "509",
    LerTodosPlanosComunicacao: "510",
    ProducaoTodosPlanosComunicacao: "511",
    RevisaoTodosPlanosComunicacao: "512",
    ComentarTodosPlanosComunicacao: "513",
    LerClientesPlanosComunicacao: "514",
    ProducaoClientesPlanosComunicacao: "515",
    RevisaoClientesPlanosComunicacao: "516",
    ComentarClientesPlanosComunicacao: "517",
    LerTodosClientes: "518",
    AdicionarClientes: "519",
    EditarFinanceiroTodosClientes: "520",
    EditarTodosClientes: "521",
    AtendimentoTodosClientes: "522",
    LerMeusClientes: "523",
    EditarMeusClientes: "524",
    AtendimentoMeusClientes: "525",
    LerTodosSugestoesConteudo: "526",
    CriarTodosSugestoesConteudo: "527",
    ExcluirTodosSugestoesConteudo: "528",
    LerClientesSugestoesConteudo: "529",
    CriarClientesSugestoesConteudo: "530",
    ExcluirClientesSugestoesConteudo: "531",
    RelatorioTodosPublicacoes: "532",
    RelatorioClientesPublicacoes: "533",
    RelatorioTodosPedidos: "534",
    RelatorioEngajamento: "535",
    RelatorioClientesPedidos: "536",
    RelatorioOpsCs: "549",
    RelatorioPlanoAcao: "552",
    TermosUso: "537",
    TermosUsoGerenciar: "538",
    Segmentos: "539",
    SegmentosGerenciar: "540",
    TemplateEmails: "541",
    TemplateEmailsGerenciar: "542",
    LerUsuarios: "543",
    CriarUsuarios: "544",
    EditarUsuarios: "545",
    AlterarSenhaUsuarios: "546",
    FazerPedidoTodos: "547",
    FazerPedidoClientes: "548",
    VisualizarLinks:"550",
    EditarLinks: "551",

    EnvioEmails: "553",
    ComercialFormularioContatos: "554",
    AcessarCalendarioFeriados: "555",
    ExcluirLinks: "556"
};

export const RolesDisplayRelatorios = [
    Roles.LerTodosClientes,
    Roles.RelatorioTodosPublicacoes, Roles.RelatorioClientesPublicacoes, 
    Roles.RelatorioTodosPedidos, Roles.RelatorioClientesPedidos,
    Roles.RelatorioEngajamento, 
    Roles.RelatorioOpsCs,
    Roles.RelatorioPlanoAcao
]

const addMenu = (rolesNecessarias, userRoles, menuList, menuItem) => {
    for (let index = 0; index < rolesNecessarias.length; index++) {
        const role = rolesNecessarias[index];
        if (userRoles.includes(role)) {
            menuList.push(menuItem)
            break;
        }
    }
    return menuList;
}
export const getMenu = () => {
    const userRoles = store.getters['account/roles']
    const isCliente = store.getters['account/isCliente']
    const isMention = store.getters['account/isMention']
    let menu = [
        {
            "id": "home",
            "label": "Home",
            "icon": "fas fa-home",
            "link": "/",
            "routes": ["home"],
            "subItems": null
        }
    ]

    if (isCliente === true) {
        menu = addMenu([Roles.CriarMeusPedidos], userRoles, menu, {
            "id": "novo-pedido",
            "label": "Novo Pedido",
            "icon": "fas fa-shopping-cart",
            "link": "/novo-pedido",
            "routes": ["/novo-pedido"],
            "subItems": null
        })
        menu = addMenu([Roles.LerMinhasSugestoes], userRoles, menu, {
            "id": "sugestoes-conteudo",
            "label": "Sugestões de Conteúdo",
            "icon": "fas fa-lightbulb",
            "link": "/minhas-sugestoes",
            "routes": ["/minhas-sugestoes"],
            "subItems": null
        })
        menu = addMenu([Roles.LerMeusPedidos], userRoles, menu, {
            "id": "meus-pedidos",
            "label": "Meus Pedidos",
            "icon": "fas fa-box",
            "link": "/meus-pedidos",
            "routes": ["/meus-pedidos"],
            "subItems": null
        })
        menu = addMenu([Roles.LerMeuPlanoComunicacao], userRoles, menu, {
            "id": "meu-plano-comunicacao",
            "label": "Plano de Comunicação",
            "icon": "fas fa-bullhorn",
            "link": "/meu-plano-comunicacao",
            "routes": ["/meu-plano-comunicacao"],
            "subItems": null
        })
        menu = addMenu([Roles.LerMinhaEmpresa], userRoles, menu, {
            "id": "minha-conta",
            "label": "Minha Conta",
            "icon": "fas fa-building",
            "link": "/minha-conta",
            "routes": ["/minha-conta"],
            "subItems": null
        })
        menu = addMenu([Roles.LerMeusUsuarios], userRoles, menu, {
            "id": "meus-usuarios",
            "label": "Usuários",
            "icon": "fas fa-users",
            "link": "/meus-usuarios",
            "routes": ["/meus-usuarios"],
            "subItems": null
        })
        menu = addMenu([Roles.RelatorioPublicacoes], userRoles, menu, {
            "id": "minhas-publicacoes",
            "label": "Minhas Publicações",
            "icon": "fas fa-newspaper",
            "link": "/minhas-publicacoes",
            "routes": ["/minhas-publicacoes"],
            "subItems": null
        })
    } else if (isMention === true) {
        menu = addMenu([Roles.LerTodosClientes, Roles.LerMeusClientes], userRoles, menu, {
            "id": "gestao-clientes",
            "label": "Gestão de Clientes",
            "icon": "fa-solid fa-users-gear",
            "link": "/gestao-clientes",
            "routes": ["/gestao-clientes"],
            "subItems": null
        })
        menu = addMenu([Roles.LerTodosPedidos, Roles.LerClientesPedidos], userRoles, menu, {
            "id": "pedidos",
            "label": "Pedidos",
            "icon": "fas fa-box",
            "link": "/pedidos",
            "routes": ["/pedidos"],
            "subItems": null
        })
        menu = addMenu([Roles.FazerPedidoTodos, Roles.FazerPedidoClientes], userRoles, menu, {
            "id": "fazer-pedido",
            "label": "Fazer Pedido",
            "icon": "fas fa-shopping-cart",
            "link": "/fazer-pedido",
            "routes": ["/fazer-pedido"],
            "subItems": null
        })
        menu = addMenu([Roles.LerTodosSugestoesConteudo, Roles.LerClientesSugestoesConteudo], userRoles, menu, {
            "id": "sugestoes-conteudo",
            "label": "Sugestões de Conteúdo",
            "icon": "fas fa-lightbulb",
            "link": "/sugestoes-conteudo",
            "routes": ["/sugestoes-conteudo"],
            "subItems": null
        })
        menu = addMenu([Roles.LerTodosClientes, Roles.LerMeusClientes], userRoles, menu, {
            "id": "clientes",
            "label": "Clientes",
            "icon": "fas fa-user-tie",
            "link": "/clientes",
            "routes": ["/clientes"],
            "subItems": null
        })
        menu = addMenu([Roles.LerUsuarios], userRoles, menu, {
            "id": "usuarios",
            "label": "Usuários",
            "icon": "fas fa-users",
            "link": "/usuarios",
            "routes": ["/usuarios"],
            "subItems": null
        })

        menu = addMenu(RolesDisplayRelatorios, 
            userRoles, menu, {
            "id": "relatorios",
            "label": "Relatórios",
            "icon": "fa-regular fa-file-chart-column",
            "link": "/relatorios",
            "routes": [
                "/relatorios", 
                "/relatorio-publicacoes",
                "/relatorio-pedidos",
                "/relatorio-engajamento",
                "/relatorio-opscs",
                "/relatorio-plano-acao",
                "/relatorio-pesquisa-satisfacao"
            ],
            "subItems": null
        })

        menu = addMenu([Roles.VisualizarLinks], userRoles, menu, {
            "id": "repositorio-links",
            "label": "Repositórios de Links",
            "icon": "fa-solid fa-files",
            "link": "/repositorio-links",
            "routes": ["/repositorio-links"],
            "subItems": null
        })

        menu = addMenu([Roles.ComercialFormularioContatos], userRoles, menu, {
            "id": "formulario-contatos",
            "label": "Formulário de Contatos",
            "icon": "fa-solid fa-comment-arrow-up-right",
            "link": "/formulario-contatos",
            "routes": ["/formulario-contatos"],
            "subItems": null
        })

        menu = addMenu([Roles.Segmentos], userRoles, menu, {
            "id": "segmentos",
            "label": "Segmentos",
            "icon": "fas fa-grid",
            "link": "/segmentos",
            "routes": ["/segmentos"],
            "subItems": null
        })
        menu = addMenu([Roles.TermosUso], userRoles, menu, {
            "id": "termos-uso",
            "label": "Termos de Uso",
            "icon": "fas fa-user-shield",
            "link": "/termos-uso",
            "routes": ["/termos-uso"],
            "subItems": null
        })
        menu = addMenu([Roles.TemplateEmails], userRoles, menu, {
            "id": "templates-notificacoes",
            "label": "Gerenciar Notificações",
            "icon": "fas fa-envelope-open-text",
            "link": "/templates-notificacoes",
            "routes": ["/templates-notificacoes"],
            "subItems": null
        })

        menu = addMenu([Roles.EnvioEmails], userRoles, menu, {
            "id": "envio-emails",
            "label": "Envio de Emails",
            "icon": "fa-thin fa-paper-plane",
            "link": "/envio-emails",
            "routes": ["/envio-emails"],
            "subItems": null
        })
        menu = addMenu([Roles.AcessarCalendarioFeriados], userRoles, menu, {
            "id": "calendario-feriados",
            "label": "Feriados",
            "icon": "fa fa-calendar-check",
            "link": "/calendario-feriados",
            "routes": ["/calendario-feriados"],
            "subItems": null
        })
    }

    menu.push({
        "id": "perguntas-frequentes",
        "label": "Perguntas Frequentes",
        "icon": "fas fa-question",
        "link": "/perguntas-frequentes",
        "routes": ["/perguntas-frequentes"],
        "subItems": null
    })

    if (isCliente === true) {
        menu.push({
            "id": "atendimento-whatsapp",
            "label": "Atendimento",
            "icon": "fa-solid fa-message-question",
            "to": {name: 'home', query: {openChat: true}},
            "routes": [],
            "subItems": null
        })
    }

    return menu
}


export default Roles;