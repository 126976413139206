import { createWebHistory, createRouter } from "vue-router";
import routes from './routes';
import appConfig from "../../app.config";
import store from "@/state/store";
import { tryInitTrackUserTime } from "@/utils/tos";
import { isInRoles } from "@/utils/authorize.directive";
import Roles from "@/utils/roles";

const router = createRouter({
  history: createWebHistory(),
  routes,
  // Simulate native-like scroll behavior when navigating to a new
  // route and using back/forward buttons.
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0, left: 0 };
    }
  },
});

// API based Authentication
// Before each route evaluates...
router.beforeEach((routeTo, routeFrom, next) => {
  const authRequired = routeTo.matched.some((route) => route.meta.authRequired);
  var loggedIn = store.getters['account/loggedIn']

  if (authRequired && !loggedIn) {
    //return next('/login', { redirect: routeTo.fullPath });
    return next({
      path: '/login',
      query: { redirectTo: routeTo.fullPath },
    });
  }
  if (loggedIn) {
    tryInitTrackUserTime(store.getters['account/userEmail']);
  }
  
  //se tiver que preencher dados, so pode ir para as telas de preenchimento
  //se estiver indo para agendar kickoff pular mesmo que tenha que responder pesquisa
  if (loggedIn && routeTo.name == 'nova-conta-agenda-kickoff') {
    next();
    return;
  }
  if (authRequired && isInRoles(Roles.FillClientData) && !routeTo.meta.roles.includes(Roles.FillClientData)) {
    return next('/minha-conta/preencher/dados-basicos');
  }
  if (authRequired && isInRoles(Roles.ResponderPesquisa) && !routeTo.meta.roles.includes(Roles.ResponderPesquisa)) {
    return next('/responder-pesquisa');
  }
  if (authRequired && isInRoles(Roles.AcceptTerms) && !routeTo.meta.roles.includes(Roles.AcceptTerms)) {
    return next('/termos-e-condicoes');
  }
  next();
});

router.beforeResolve(async (routeTo, routeFrom, next) => {
  // Create a `beforeResolve` hook, which fires whenever
  // `beforeRouteEnter` and `beforeRouteUpdate` would. This
  // allows us to ensure data is fetched even when params change,
  // but the resolved route does not. We put it in `meta` to
  // indicate that it's a hook we created, rather than part of
  // Vue Router (yet?).
  try {
    // For each matched route...
    for (const route of routeTo.matched) {
      await new Promise((resolve, reject) => {
        // If a `beforeResolve` hook is defined, call it with
        // the same arguments as the `beforeEnter` hook.
        if (route.meta && route.meta.beforeResolve) {
          route.meta.beforeResolve(routeTo, routeFrom, (...args) => {
            // If the user chose to redirect...
            if (args.length) {
              // If redirecting to the same route we're coming from...
              // Complete the redirect.
              next(...args);
              reject(new Error('Redirected'));
            } else {
              resolve();
            }
          });
        } else {
          // Otherwise, continue resolving the route.
          resolve();
        }
      });
    }
    // If a `beforeResolve` hook chose to redirect, just return.
  } catch (error) {
    return;
  }
  document.title = routeTo.meta.title + ' | ' + appConfig.title;
  // If we reach this point, continue resolving the route.
  next();
});

export default router;