import Roles, { RolesDisplayRelatorios } from "@/utils/roles"

export default [
  {
    path: "/relatorios",
    name: "relatorios",
    component: () => import("@/views/area-mention/relatorios/index.vue"),
    meta: {
      title: 'Relatórios',
      authRequired: true,
      roles: RolesDisplayRelatorios
    },
  },
  {
    path: "/relatorio-publicacoes",
    name: "relatorio-publicacoes",
    component: () => import("@/views/area-mention/relatorios/relatorio-publicacoes/index.vue"),
    meta: {
      title: 'Relatório de Publicações',
      authRequired: true,
      roles: [Roles.RelatorioClientesPublicacoes, Roles.RelatorioTodosPublicacoes]
    },
  },
  {
    path: "/relatorio-pedidos",
    name: "relatorio-pedidos",
    component: () => import("@/views/area-mention/relatorios/relatorio-pedidos/index.vue"),
    meta: {
      title: 'Relatório de Pedidos',
      authRequired: true,
      roles: [Roles.RelatorioClientesPedidos, Roles.RelatorioTodosPedidos]
    },
  },
  {
    path: "/relatorio-engajamento",
    name: "relatorio-engajamento",
    component: () => import("@/views/area-mention/relatorios/relatorio-engajamento/index.vue"),
    meta: {
      title: 'Relatório de Engajamento',
      authRequired: true,
      roles: [Roles.RelatorioEngajamento]
    },
  },
  {
    path: "/relatorio-opscs",
    name: "relatorio-opscs",
    component: () => import("@/views/area-mention/relatorios/relatorio-opscs/index.vue"),
    meta: {
      title: 'Relatório de OPS/CS',
      authRequired: true,
      roles: [Roles.RelatorioOpsCs]
    },
  },
  {
    path: "/relatorio-plano-acao",
    name: "relatorio-plano-acao",
    component: () => import("@/views/area-mention/relatorios/relatorio-plano-acao/index.vue"),
    meta: {
      title: 'Relatório de Plano de Ação - Fim de contrato',
      authRequired: true,
      roles: [Roles.RelatorioPlanoAcao]
    },
  },
  {
    path: "/relatorio-pesquisa-satisfacao",
    name: "relatorio-pesquisa-satisfacao",
    component: () => import("@/views/area-mention/relatorios/relatorio-pesquisa-satisfacao/index.vue"),
    meta: {
      title: 'Relatório Pesquisa de Satisfação',
      authRequired: true,
      roles: [Roles.LerTodosClientes] 
    },
  },
];


